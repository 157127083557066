import styled, { css } from "styled-components";
import { deviceMF, deviceDF } from "../breakPoints";
import { fadeIn } from "../animation";
import { blue, grey, red, white } from "../colors";

export const HeroContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.autoHeight ? "auto" : "103vh")};
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -107px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 900px;
  background-color: ${blue};
  @media ${deviceDF.laptop} {
    height: 600px;
  }
  @media ${deviceDF.tablet} {
    height: 820px;
  }
`;
export const ImageSlider = styled.img`
  ${fadeIn()}
  width: 100%;
  height: 900px;
  object-fit: cover;  
  background-color: ${blue};
  opacity: 0.5;
  z-index: 1;
  @media ${deviceDF.laptop} {
    height: 600px;
  }
  @media ${deviceDF.tablet} {
    height: 820px;
  }
`;
export const ImageSliderMobile = styled.img`
  ${fadeIn()}
  width: 100%;
  height: 300px;
  object-fit: cover;
  z-index: 1;
`;
export const SliderWrapperMobile = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-color: ${blue};
`;
export const HeroTitleContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
export const HeroRedTitle = styled.div`
  @keyframes slidein {
    from {
      margin-left: 300%;
      width: 1440px
    }

    to {
      margin-left: 0%;
      width: 750px;
    }
  }

  font-family: "Conv_Poppins-SemiBoldItalic", sans-serif;
  color: ${white};
  background-color: ${red};
  font-size: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
  text-align: center;
  animation-duration: 2.5s;
  animation-name: slidein;
  @media ${deviceMF.tablet} {
    font-size: 2rem;
    width: 750px;
  }
  @media ${deviceDF.tablet} {
    animation-name: none;
  }
`;
export const HeroTitle = styled.h1` 
  @keyframes slidein {
    from {
      margin-left: 300%;
      width: 1440px
    }

    to {
      margin-left: 0%;
      width: 750px;
    }
  }


  font-family: "Conv_Poppins-SemiBoldItalic", sans-serif;
  color: ${white};
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8);
  font-size: 1.5rem;
  text-align: center;
  animation-duration: 2s;
  animation-name: slidein;
  @media ${deviceMF.tablet} {
    font-size: 2rem;
  }
`;
export const Blur = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${blue};
  opacity: 0.5;
  z-index: 1;
`;
export const BlurAbsolute = styled(Blur)`
  position: absolute;
`;
export const SectionStyled = styled.section`
  width: 100%;
  padding: 4rem 1.4rem;
  background-color: ${white};
  @media ${deviceMF.tablet} {
    padding: 4rem 4rem;
  }
`;
export const SectionStyledFull = styled(SectionStyled)`
  margin-bottom: 100px;
  @media ${deviceMF.tablet} {
    margin-bottom: 200px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
`;
export const MiniTitle = styled.h5`
  color: ${grey};
  font-size: 14px;
  margin: 0 0 1rem;
`;
export const Title = styled.h2`
  font-size: 28px;
  line-height: 2.2rem;
  color: ${(props) => (props.color !== "primary" ? white : blue)};
  @media ${deviceMF.tablet} {
    font-size: 48px;
    line-height: 3.2rem;
  }
`;
export const TitleStrong = styled.strong`
  font-family: "Conv_Poppins-Bold", sans-serif;
`;
export const ButtonContainer = styled.div`
  align-self: flex-end;
`;
export const SquaresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${deviceMF.tablet} {
    align-items: stretch;
    flex-direction: row;
  }
`;
export const SquareContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @media ${deviceDF.mobileL} {
    flex-direction: row;
  }
  @media ${deviceMF.mobileL} {
    margin-bottom: 0;
  }
`;
export const SquareRow = styled.div`
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  @media ${deviceMF.mobileL} {
    flex-direction: row;
  }
`;
export const SquareStyled = styled.div`
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${blue};
  color: ${white};
  margin: 0.3rem;
  @media ${deviceDF.tablet} {
    width: 180px;
    height: 180px;
  }
  @media ${deviceDF.tablet} {
    width: 140px;
    height: 140px;
  }
`;
export const CustomImageWrapper = styled.div`
  border: 2px solid ${blue};
  width: 100%;
  height: 250px;
  padding: 5px;
  margin: 0 6px;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: content-box;
  position: relative;
  @media ${deviceMF.tablet} {
    height: auto;
  }
`;
export const StationsSection = styled(SectionStyled)`
  background-color: ${blue};
  padding-bottom: 100px;
  position: relative;
  @media ${deviceMF.tablet} {
    padding-bottom: 200px;
  }
`;
export const StationsSectionBadge = styled.span`
  background-color: ${red};
  width: 90%;
  color: ${white};
  text-align: center;
  position: absolute;
  padding: 1.2rem auto;
  font-size: 48px !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${deviceDF.laptop} {
    font-size: 38px !important;
  }
  @media ${deviceDF.mobileL} {
    font-size: 28px !important;
  }
`;
export const StationsImagesWrapper = styled.div`
  width: 310px;
  height: 450px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 4rem;
  @media ${deviceMF.mobileL} {
    width: 450px;
  }
  @media ${deviceMF.tablet} {
    width: 600px;
  }
  @media ${deviceMF.laptop} {
    width: 800px;
  }
  @media ${deviceDF.mobileL} {
    display: none;
  }
`;
export const StationsImagesWrapperMobile = styled.div`
  width: 310px;
  height: 350px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 4rem;
  @media ${deviceMF.mobileL} {
    display: none;
  }
  @media ${deviceDF.mobileS} {
    width: 270px;
  }
`;
export const StationImage = styled(CustomImageWrapper)`
  width: 320px;
  height: 380px;
  border: 2px solid ${(props) => (props.color !== "primary" ? white : blue)};
  position: absolute;
  ${(props) => props.left && stationLeft}
  ${(props) => props.center && stationCenter}
  ${(props) => props.right && stationRight}
  @media ${deviceDF.tablet} {
    width: 240px;
    height: 300px;
  }
  @media ${deviceDF.mobileL} {
    width: 200px;
    height: 280px;
  }
`;
const stationLeft = css`
  left: 0;
  z-index: 3;
  @media ${deviceMF.mobileL} {
    left: -15%;
  }
  @media ${deviceMF.tablet} {
    left: 0;
  }
`;
const stationCenter = css`
  right: 50%;
  bottom: 0;
  transform: translate(50%, 0);
  @media ${deviceMF.tablet} {
    right: 50%;
    bottom: 0;
    transform: translate(50%, 0);
  }
`;
const stationRight = css`
  right: 0%;
  top: -40px;
  @media ${deviceMF.mobileL} {
    right: -15%;
    top: -40px;
  }
  @media ${deviceMF.tablet} {
    right: 0;
    top: -80px;
  }
`;
export const StationButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const ValuesWrapper = styled.div`
  margin-bottom: 4rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 10rem;
  }
`;
export const ValuesTitle = styled.h2`
  text-align: center;
  font-family: "Conv_Poppins-SemiBoldItalic", sans-serif;
  margin-bottom: 1rem;
  font-size: 1.6rem;
`;
export const ValuesSquaresRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Conv_Poppins-Bold", Sans-Serif;
  @media ${deviceMF.tablet} {
    justify-content: center;
    flex-direction: row;
  }
`;
export const SquareValues = styled(SquareStyled)`
  margin: 0 0 1rem;
  text-align: center;
  @media ${deviceMF.tablet} {
    margin: 0 1rem;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 3rem;
  text-align: justify;

  @media ${deviceMF.tablet} {
    text-align: start;
  }
`;
export const StationsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  @media ${deviceMF.tablet} {
    justify-content: space-between;
  }
`;
export const StationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-bottom: 3.5rem;
  @media ${deviceDF.laptop} {
    width: 240px;
  }
`;
export const StationImageCircle = styled(CustomImageWrapper)`
  padding: 1rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  width: 280px;
  height: 280px;
  border: 2px solid ${(props) => (props.color !== "primary" ? white : blue)};
  &:hover {
    transform: scale(1.15);  
  }

  @media ${deviceMF.mobileL} {
    width: 300px;
    height: 300px;
  }
`;
export const StationButtons = styled.div`
  display: flex;
  margin-top: 1rem;
`;
export const StationsText = styled.p`
  font-size: 1rem;
  text-align: center;
  font-family: "Conv_Poppins-Bold", sans-serif;
  @media ${deviceMF.mobileL} {
    font-size: 1.1rem;
  }
`;
export const StationsTitle = styled.p`
  font-size: 1.4rem;
  text-align: center;
  font-family: "Conv_Poppins-Bold", sans-serif;
  @media ${deviceMF.mobileL} {
    font-size: 1.5rem;
  }
`;
export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${deviceMF.tablet} {
    flex-direction: row;
    justify-content: space-around;
  }
`;
export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 8rem;
    width: 40%;
  }
`;
export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;
export const ContactFormTitle = styled.h2`
  background-color: ${blue};
  color: ${white};
  font-size: 28px;
  text-align: center;
  margin-bottom: 1.5rem;
  @media ${deviceMF.tablet} {
    font-size: 32px;
  }
`;
export const InputStyled = styled.input`
  border: none;
  background-color: ${(props) => (props.color !== "primary" ? blue : red)};
  padding: 0.4rem 0.8rem;
  font-size: 14px;
  color: ${white};
  font-family: "Conv_Poppins-Bold", sans-serif;
  margin-bottom: 0.55rem;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  @media ${deviceMF.mobileL} {
    font-size: 1rem;
  }
`;
export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
export const FileLabel = styled.label`
  border: none;
  background-color: ${(props) => (props.color !== "primary" ? blue : red)};
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
  color: ${white};
  font-family: "Conv_Poppins-Bold", sans-serif;
  margin-bottom: 0.3rem;
  cursor: pointer;
`;
export const ContactBadge = styled.div`
  @keyframes slideinContactBadge {
    from {
      margin-left: 300%;
      width: 1440px
    }

    to {
      margin-left: 0%;
      width: 720px;
    }
  }

  background-color: rgba(22, 94, 156, 0.75);
  z-index: 3;
  width: 300px;
  height: 200px;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  position: absolute;
  padding: 0 120px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-duration: 2.5s;
  animation-name: slideinContactBadge;
  @media ${deviceMF.tablet} {
    border-radius: 3rem;
    top: 50%;
    left: 50%;
    width: 720px;
    height: 300px;
  }
  @media ${deviceDF.tablet} {
    animation-name: none;
  }
  @media ${deviceDF.mobileL} {
    padding: 30px;
  }
`;
export const ContactBadgeTitle = styled.div`
  color: ${white};
  font-family: 'Conv_Poppins-Bold';
  font-size: 38px;
  line-height : 60px;
  margin-bottom: 25px;
  @media ${deviceMF.tablet} {
    font-size: 52px;
  }  
  @media ${deviceDF.mobileL} {
  margin-bottom: 15px;
  }
`;
export const StationLink = styled.a`
  font-family: "Conv_Poppins-Regular", Sans-Serif;
  color: ${(props) => (props.color === "white" ? blue : white)};
  text-decoration: none;
`;
