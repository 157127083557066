import React from "react";

import {
  BlurAbsolute,
  HeroContainer,
  HeroRedTitle,
  HeroTitleContainer,
  SectionStyledFull,
  TitleWrapper,
  MiniTitle,
  Title,
  TitleStrong,
  StationsWrapper,
  StationImageCircle,
  StationContainer,
  StationButtons,
  StationsText,
  StationsTitle,
  StationLink,
} from "../../assets/styles/pages/pages";
import { Button } from "../../components/Button/Button";

import Background from "../../assets/images/background-3.png";
import BackgroundStations from "../../assets/images/background-1.png";

import Belisario from "../../assets/images/stations/Belisario.jpg";
import Caji from "../../assets/images/stations/Caji.jpg";
import RealAColima from "../../assets/images/stations/RealAColima.jpg";
import Robles from "../../assets/images/stations/Robles.jpg";
import SanSebastian from "../../assets/images/stations/SanSebastian.jpg";
import SantaPaula from "../../assets/images/stations/SantaPaula.jpg";
import Tepic from "../../assets/images/stations/Tepic.jpg";
import Tetlan from "../../assets/images/stations/Tetlan.jpg";

export const Stations = () => {
  return (
    <>
      <HeroContainer src={Background}>
        <HeroTitleContainer>
          <HeroRedTitle>Factura tu servicio de forma cómoda</HeroRedTitle>
        </HeroTitleContainer>
        <BlurAbsolute />
      </HeroContainer>
      <SectionStyledFull>
        <TitleWrapper>
          <div>
            <MiniTitle>Estaciones</MiniTitle>
            <Title color="primary">
              8 Estaciones
              <br />
              <TitleStrong>Contigo.</TitleStrong>
            </Title>
          </div>
        </TitleWrapper>
        <StationsWrapper>
          <StationContainer>
            <StationImageCircle color="primary" src={Belisario} />
            <div>
              <StationsTitle>ESTACIÓN ARB SA DE CV</StationsTitle>
              <StationsText>Av. Belisario Domínguez #2643,</StationsText>
              <StationsText>CP 44300, Col. La esperanza,</StationsText>
              <StationsText>Guadalajara, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/KVKqHsueQRtBxnG77">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={SanSebastian} />
            <div>
              <StationsTitle>GASEGURO SA DE CV</StationsTitle>
              <StationsText>Camino de San Sebastián #4987,</StationsText>
              <StationsText>CP 45650, Sebastián el Grande,</StationsText>
              <StationsText>Tlajomulco de Zúñiga, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/nkWaCPSMYr3CKsbq8">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={RealAColima} />
            <div>
              <StationsTitle>ESTACIÓN PETROMEX SA DE CV</StationsTitle>
              <StationsText>Camino Real a Colima #385,</StationsText>
              <StationsText>CP 45600, Col. Las Pomas,</StationsText>
              <StationsText>Tlaquepaque, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/BFTpWDzD86Rgq41P8">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={Caji} />
            <div>
              <StationsTitle>GASOLINERA CUESCO SA DE CV</StationsTitle>
              <StationsText>Carretera Cajititlán #800,</StationsText>
              <StationsText>CP 34220, Col. La Tijera,</StationsText>
              <StationsText>Tlajomulco de Zúñiga, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/ohwSenYbrjd6U8YZ9">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={BackgroundStations} />
            <div>
              <StationsTitle>PRESTIGIO Y SOLVENCIA SA DE CV</StationsTitle>
              <StationsText>Salvador Orozco Loreto #2788,</StationsText>
              <StationsText>CP 45589, Col. Las Huertas</StationsText>
              <StationsText>Tlaquepaque, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/XWZTR8rob9uPvPQZ8">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="http://facturaciónprestigio.ddns.net/controlgasfe">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={SantaPaula} />
            <div>
              <StationsTitle>GASOLINERA SANTA PAULA SA DE CV</StationsTitle>
              <StationsText>José Álvarez Franco #485,</StationsText>
              <StationsText> CP 45420, Col. Santa Paula,</StationsText>
              <StationsText>Tonalá, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://maps.app.goo.gl/rSwHHra14otberzG7">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={Tepic} />
            <div>
              <StationsTitle>GASOLINERA AG SA DE CV</StationsTitle>
              <StationsText>Carretera Tepic Puerto Vallarta #531,</StationsText>
              <StationsText>CP 63738, Mezcales,</StationsText>
              <StationsText>Bahía de Banderas, Nayarit</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://maps.app.goo.gl/cTeZ2PLUxGhHVMTH7">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="https://www.controlnet.com.mx/Factura">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={Tetlan} />
            <div>
              <StationsTitle>SERVI GAXMAR SC DE RL DE CV</StationsTitle>
              <StationsText>Av. Malecón 165,</StationsText>
              <StationsText>CP 44820, Colonia Tetlan,</StationsText>
              <StationsText>Guadalajara, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/wqVKpN32yuVhv6Tk8">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="http://es13372.migasolinera.net/bajafactura/">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
          <StationContainer>
            <StationImageCircle color="primary" src={Robles} />
            <div>
              <StationsTitle>GASOLINERA LOS ROBLES SA DE CV</StationsTitle>
              <StationsText>Av. Juan Gil Preciado 7611,</StationsText>
              <StationsText>CP 45138, Colonia Nuevo México,</StationsText>
              <StationsText>Zapopan, Jal.</StationsText>
            </div>
            <StationButtons>
              <Button>
                <StationLink href="https://goo.gl/maps/Mcv426JZ9xKNhLhSA">MAPA</StationLink>
              </Button>
              <Button color="primary">
                <StationLink href="http://es13372.migasolinera.net/bajafactura/">FACTURAR</StationLink>
              </Button>
            </StationButtons>
          </StationContainer>
        </StationsWrapper>
      </SectionStyledFull>
    </>
  );
};
