import styled from "styled-components";
import { deviceMF } from "../breakPoints";
import { blue, red, white } from "../colors";

const color = {
  primary: red,
  white: white,
  default: blue,
};

export const ButtonStyled = styled.button`
  font-family: "Conv_Poppins-Regular", Sans-Serif;
  color: ${(props) => (props.color === "white" ? blue : white)};
  background-color: ${(props) =>
    !props.color ? color.default : color[props.color]};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: bold;
  font-size: ${(props) => (props.anchorStyled ? "18px" : "14px")};
  margin: ${(props) => (props.margin ? "0" : "0 4px")};
  padding: ${(props) => (props.size === "big" ? "15px 40px" : "2px 12px")};
  cursor: pointer;
  transition: all 0.5s ease;
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    transform: translate(0px, -10px);
  }

  @media ${deviceMF.mobileL} {
    font-size: ${(props) => (props.anchorStyled ? "30px" : "16px")};
    
  }
  @media ${deviceMF.tablet} {
    font-size: ${(props) => (props.anchorStyled ? "34px" : "18px")};
  }
`;
