import React from "react";
import { useHistory } from "react-router-dom";
import { BurgerIcon } from "../BurgerIcon";
import {
  MenuContainer,
  MenuList,
  MenuListItem,
  CloseContainer,
} from "./styles";

export const BurgerMenu = ({ close }) => {
  let history = useHistory();

  const handleRedirect = (path) => {
    history.push(path);
    close();
  };

  return (
    <>
      <CloseContainer>
        <BurgerIcon onClick={close} open />
      </CloseContainer>
      <MenuContainer>
        <MenuList>
          <MenuListItem onClick={() => handleRedirect("/")}>
            Inicio
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/nosotros")}>
            Nosotros
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/contacto")}>
            Hagamos Equipo
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/estaciones")}>
            Estaciones
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/estaciones")}>
            Facturación
          </MenuListItem>
        </MenuList>
      </MenuContainer>
    </>
  );
};
