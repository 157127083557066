import React from "react";
import { useHistory } from "react-router";
import Popup from "reactjs-popup";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  HeroContainer,
  MiniTitle,
  SectionStyled,
  Title,
  TitleStrong,
  TitleWrapper,
  ButtonContainer,
  SquareStyled,
  SquareContainer,
  SquareRow,
  CustomImageWrapper,
  SquaresWrapper,
  Blur,
  HeroTitleContainer,
  HeroTitle,
  StationsSection,
  StationsSectionBadge,
  StationsImagesWrapper,
  StationsImagesWrapperMobile,
  StationImage,
  StationButtonContainer,
  SliderWrapper,
  SliderWrapperMobile,
  ImageSlider,
  ImageSliderMobile
} from "../../assets/styles/pages/pages";
import { Button } from "../../components/Button/Button";
import { VideoPopUp } from "../../components/VideoPopUp";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "../../assets/styles/swipper.css";

// Hero
import SliderImage0 from "../../assets/images/home0.jpg";
import SliderImage1 from "../../assets/images/home1.jpg";
import SliderImage2 from "../../assets/images/home2.jpg";

import LoHacemosJuntos from "../../assets/images/loHacemosJuntos.jpg";
import BackgroundStations1 from "../../assets/images/stations/preview/Belisario.jpg";
import BackgroundStations2 from "../../assets/images/stations/preview/Caji.jpg";
import BackgroundStations3 from "../../assets/images/stations/preview/SantaPaula.jpg";

import Belisario from "../../assets/images/stations/Belisario.jpg";
import Caji from "../../assets/images/stations/Caji.jpg";
import RealAColima from "../../assets/images/stations/RealAColima.jpg";
import Robles from "../../assets/images/stations/Robles.jpg";
import SanSebastian from "../../assets/images/stations/SanSebastian.jpg";
import SantaPaula from "../../assets/images/stations/SantaPaula.jpg";
import Tepic from "../../assets/images/stations/Tepic.jpg";
import Tetlan from "../../assets/images/stations/Tetlan.jpg";

SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

const contentStyle = {
  background: "rgba(232,191,19,0)",
  width: "100vw",
  height: "100vh",
  border: "none",
};

export const Home = () => {
  const history = useHistory();
  return (
    <>
      <HeroContainer autoHeight>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoHeight
          loop
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1000*5,
            disableOnInteraction: false,
          }}
        >          
          <SwiperSlide>
            <SliderWrapper>
              <ImageSlider src={SliderImage0} alt="Slider 0" />
            </SliderWrapper>
          </SwiperSlide>
          <SwiperSlide>
            <SliderWrapper>
              <ImageSlider src={SliderImage1} alt="Slider 1" />
            </SliderWrapper>
          </SwiperSlide>
          <SwiperSlide>
            <SliderWrapper>
              <ImageSlider src={SliderImage2} alt="Slider 2" />
            </SliderWrapper>
          </SwiperSlide>
        </Swiper>
        <HeroTitleContainer>
          <HeroTitle>Comprometidos con México</HeroTitle>
          <Popup
              modal
              overlayStyle={{ background: "rgba(34,34,34,.75)" }}
              contentStyle={contentStyle}
              closeOnDocumentClick={false}
              trigger={(open) => (
                <Button open={open} color="primary">
                  Ver Video
                </Button>
              )}
            >
              {(close) => <VideoPopUp close={close} />}
            </Popup>
          {/* <Button color="primary">Ver Video</Button> */}
        </HeroTitleContainer>
      </HeroContainer>
      <SectionStyled>
        <TitleWrapper>
          <div>
            <MiniTitle>Nosotros</MiniTitle>
            <Title color="primary">
              Lo hacemos
              <br />
              <TitleStrong>posible contigo</TitleStrong>
            </Title>
          </div>
          <ButtonContainer>
            <Button onClick={() => history.push("/nosotros")} color="primary">
              Ver más
            </Button>
          </ButtonContainer>
        </TitleWrapper>
        <SquaresWrapper>
          <SquareContainer>
            <SquareRow>
              <SquareStyled marginBottom marginRight>
                <h4>Crecimiento</h4>
              </SquareStyled>
              <SquareStyled marginBottom marginLeft>
                <h4>Fortaleza</h4>
              </SquareStyled>
            </SquareRow>
            <SquareRow>
              <SquareStyled marginTop marginRight>
                <h4>Trayectoria</h4>
              </SquareStyled>
              <SquareStyled marginTop marginLeft>
                <h4>Familia</h4>
              </SquareStyled>
            </SquareRow>
          </SquareContainer>
          <CustomImageWrapper src={LoHacemosJuntos}>
            <Blur />
          </CustomImageWrapper>
        </SquaresWrapper>
      </SectionStyled>
      <StationsSection>
        <StationsSectionBadge>
          Grupo Mexicano con 20 años de experiencía
        </StationsSectionBadge>
        <TitleWrapper>
          <div>
            <MiniTitle>Estaciones</MiniTitle>
            <Title>
              8 Estaciones
              <br />
              <TitleStrong>contigo</TitleStrong>
            </Title>
          </div>
        </TitleWrapper>
        <StationsImagesWrapper>
          <StationImage left src={BackgroundStations1} />
          <StationImage center src={BackgroundStations2} />
          <StationImage right src={BackgroundStations3} />
          <StationButtonContainer>
            <Button onClick={() => history.push("/estaciones")} color="primary">
              Ver más
            </Button>
          </StationButtonContainer>
        </StationsImagesWrapper>
        <StationsImagesWrapperMobile>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoHeight
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 1000*5,
              disableOnInteraction: false,
            }}
          >          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Belisario} alt="Belisario" />
              </SliderWrapperMobile>
            </SwiperSlide>     
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Caji} alt="Caji" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={RealAColima} alt="RealAColima" />
              </SliderWrapperMobile>
            </SwiperSlide>         
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Robles} alt="Robles" />
              </SliderWrapperMobile>
            </SwiperSlide>     
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={SanSebastian} alt="SanSebastian" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={SantaPaula} alt="SantaPaula" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Tepic} alt="Tepic" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Tetlan} alt="Tetlan" />
              </SliderWrapperMobile>
            </SwiperSlide>      
          </Swiper>
          <StationButtonContainer>
            <Button onClick={() => history.push("/estaciones")} color="primary">
              Ver más
            </Button>
          </StationButtonContainer>
        </StationsImagesWrapperMobile>
      </StationsSection>
    </>
  );
};
