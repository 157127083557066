import React from "react";
import {
  HeaderStyled,
  LogoStyled,
  NavStyled,
} from "../../assets/styles/components/navbar";
import Logo from "../../assets/images/Logo.png";
import { Button } from "../Button/Button";
import { useHistory } from "react-router";

export const Navbar = () => {
  const history = useHistory();
  return (
    <HeaderStyled>
      <LogoStyled
        onClick={() => history.push("/")}
        src={Logo}
        alt="Logo bristroil"
      />
      <NavStyled>
        <Button onClick={() => history.push("/nosotros")}>Nosotros</Button>
        <Button onClick={() => history.push("/contacto")}>Hagamos Equipo</Button>
        <Button onClick={() => history.push("/estaciones")}>Estaciones</Button>
        <Button onClick={() => history.push("/estaciones")} color="primary">Facturación</Button>
      </NavStyled>
    </HeaderStyled>
  );
};
