import React from "react";
import Background from "../../assets/images/background-1.png";
import { HashLink } from 'react-router-hash-link';
import {
  BlurAbsolute,
  HeroContainer,
  SectionStyledFull,
  TitleWrapper,
  MiniTitle,
  StationImageCircle,
  ContactContainer,
  ContactInfoContainer,
  ContactForm,
  ContactFormTitle,
  InputStyled,
  ContactBadge,
  ContactBadgeTitle,
} from "../../assets/styles/pages/pages";
import { Button } from "../../components/Button/Button";

export const Contact = () => {
  return (
    <>
      <HeroContainer src={Background}>
        <ContactBadge>
          <ContactBadgeTitle>
            Lleguemos
            <br />
            Juntos
          </ContactBadgeTitle>
          <HashLink to="#sectionJoinUp">
            <Button color="white" margin anchorStyled>Únete a nuestro EQUIPO</Button>
          </HashLink>
        </ContactBadge>
        <BlurAbsolute />
      </HeroContainer>
      <SectionStyledFull id="sectionJoinUp">
        <TitleWrapper>
          <div>
            <MiniTitle>Únete a nuestro EQUIPO</MiniTitle>
          </div>
        </TitleWrapper>
        <ContactContainer>
          <StationImageCircle color="primary" src={Background} />
          <ContactInfoContainer>
            <ContactFormTitle>Contáctanos</ContactFormTitle>
            <ContactForm action="">
              <InputStyled type="text" placeholder="Nombre" />
              <InputStyled type="email" placeholder="Correo Electrónico" />
              <InputStyled type="tel" placeholder="Teléfono" />
              <InputStyled type="text" placeholder="Ciudad" />
              {/* 
                POR EL MOMENTO NO SE VA A UTILIZAR 
                Enviar correo a: contacto@bristroilenergy.com
              */}
              {/* <FileLabel color="primary" htmlFor="file">
                Anexar CV
              </FileLabel>
              <FileInput name="file" type="file" placeholder="Anexar CV" /> */}
              <div>
                <Button color="primary" size="big" margin>Enviar</Button>
              </div>
            </ContactForm>
          </ContactInfoContainer>
        </ContactContainer>
      </SectionStyledFull>
    </>
  );
};
