import React from "react";

// Components
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { BurgerIcon } from "../BurgerIcon";
import { BurgerMenu } from "../BurgerMenu";
import Popup from "reactjs-popup";

const contentStyle = {
  background: "rgba(22, 94, 156,0)",
  width: "80%",
  border: "none",
};

export const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
      <Popup
        modal
        overlayStyle={{ background: "rgba(22, 94, 156,0.98" }}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={(open) => <BurgerIcon open={open} />}
      >
        {(close) => <BurgerMenu close={close} />}
      </Popup>
    </>
  );
};
