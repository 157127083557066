import styled from "styled-components";
import { deviceMF } from "../breakPoints";

export const HeaderStyled = styled.header`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 1.4rem 1.8rem;
  z-index: 3;
  position: relative;
  justify-content: center;
  @media ${deviceMF.laptop} {
    justify-content: space-between;
  }
`;
export const LogoStyled = styled.img`
  width: 150px;
  height: auto;
  cursor: pointer;
  @media ${deviceMF.mobileL} {
    width: 180px;
  }
  @media ${deviceMF.laptop} {
    width: 200px;
  }
`;
export const NavStyled = styled.nav`
  display: none;
  @media ${deviceMF.laptop} {
    display: flex;
  }
`;
