import React from "react";
import {
  FooterBadge,
  FooterBadgeImage,
  FooterBadgeInfo,
  FooterBadgeTitle,
  FooterBadgeTitleStrong,
  FooterCopyright,
  FooterCopyrightLeft,
  FooterCopyrightRight,
  FooterInfo,
  FooterItemTitle,
  FooterLink,
  FooterStyled,
  FooterText,
  IconStyled,
  InfoWrapper,
  FollowUs,
  FollowUsMobileScreen,
  LinkStyled,
  AnchorStyled,
} from "../../assets/styles/components/footer";
import { Button } from "../Button/Button";
import FooterBackground from "../../assets/images/juntosLlegaremos.jpg";

// icons
import Instagram from "../../assets/images/icons/instagram.png";
import Facebook from "../../assets/images/icons/facebook-logo.png";
import { useLocation } from "react-router";

export const Footer = () => {
  const location = useLocation();
  console.log(location);
  return (
    <FooterStyled>
      <FooterBadge color={location.pathname === "/" ? "" : "primary"}>
        <FooterBadgeImage
          src={FooterBackground}
          alt="Juntos Llegaremos"
        />
        <FooterBadgeInfo>
          <FooterBadgeTitle>
            Juntos <br />
            <FooterBadgeTitleStrong>Llegaremos</FooterBadgeTitleStrong>
          </FooterBadgeTitle>
          <Button color={location.pathname === "/" ? "" : "white"}>
            <AnchorStyled 
              color={location.pathname === "/" ? "" : "white"}
              href="mailto:contacto@bristroilenergy.com"
            >
              Únete a nuestro ABANDERAMIENTO
            </AnchorStyled>
          </Button>
        </FooterBadgeInfo>
      </FooterBadge>
      <FooterInfo>
        <InfoWrapper>
          <FooterItemTitle>CONTACTO</FooterItemTitle>
          <FooterText>33 3674 7350</FooterText>
          <FooterText>Guadalajara,</FooterText>
          <FooterText>Jalisco</FooterText>
        </InfoWrapper>
        <FollowUs>
          <FooterItemTitle>SÍGUENOS</FooterItemTitle>
          <IconStyled src={Instagram} alt="Icono instagram" />
          <IconStyled src={Facebook} alt="Icono facebook" />
        </FollowUs>
        <InfoWrapper>
          <FooterItemTitle>
            FACTURA
            <br /> TU SERVICIO
          </FooterItemTitle>
          <LinkStyled to="/estaciones">Ver Estaciones</LinkStyled>
        </InfoWrapper>        
        <FollowUsMobileScreen>
          <FooterItemTitle>SÍGUENOS</FooterItemTitle>
          <IconStyled src={Instagram} alt="Icono instagram" />
          <IconStyled src={Facebook} alt="Icono facebook" />
        </FollowUsMobileScreen>
      </FooterInfo>
      <FooterCopyright>
        <FooterCopyrightLeft>
          2021 BRISTROIL ENERGY. TODOS LOS DERECHOS RESERVADOS.
        </FooterCopyrightLeft>
        <FooterCopyrightRight>
          Hecho con amor <FooterLink href="https://www.instagram.com/malmocreative/">Malmo</FooterLink>.
        </FooterCopyrightRight>
      </FooterCopyright>
    </FooterStyled>
  );
};
