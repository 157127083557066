import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { GlobalStyle } from "../../assets/styles/GlobalStyles";

// views
import { Home } from "../../views/Home";
import { About } from "../../views/About";
import { Stations } from "../../views/Stations";
import { Contact } from "../../views/Contact";

// components
import { Layout } from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";

const App = () => {
  return (
    <>
      <GlobalStyle />

      <HashRouter>
        <ScrollToTop>
          <Switch>
            <Layout>
              <Route exact path="/" component={Home} />
              <Route exact path="/nosotros" component={About} />
              <Route exact path="/estaciones" component={Stations} />
              <Route exact path="/contacto" component={Contact} />
            </Layout>
            <Route component={Home} />
          </Switch>
        </ScrollToTop>
      </HashRouter>
    </>
  );
};

export default App;
