import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from "react-router";
import {
  BlurAbsolute,
  HeroContainer,
  HeroTitleContainer,
  SectionStyledFull,
  TitleWrapper,
  MiniTitle,
  Title,
  TitleStrong,
  StationsImagesWrapper,
  StationImage,
  StationButtonContainer,
  ValuesWrapper,
  SquareValues,
  ValuesSquaresRow,
  InfoContainer,
  ValuesTitle,
  HeroRedTitle,  
  StationsImagesWrapperMobile,
  SliderWrapperMobile,
  ImageSliderMobile
} from "../../assets/styles/pages/pages";
import { Button } from "../../components/Button/Button";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "../../assets/styles/swipper.css";

import Background from "../../assets/images/nosotros.jpg";
import BackgroundStations1 from "../../assets/images/stations/preview/Belisario.jpg";
import BackgroundStations2 from "../../assets/images/stations/preview/Caji.jpg";
import BackgroundStations3 from "../../assets/images/stations/preview/SantaPaula.jpg";

import Belisario from "../../assets/images/stations/Belisario.jpg";
import Caji from "../../assets/images/stations/Caji.jpg";
import RealAColima from "../../assets/images/stations/RealAColima.jpg";
import Robles from "../../assets/images/stations/Robles.jpg";
import SanSebastian from "../../assets/images/stations/SanSebastian.jpg";
import SantaPaula from "../../assets/images/stations/SantaPaula.jpg";
import Tepic from "../../assets/images/stations/Tepic.jpg";
import Tetlan from "../../assets/images/stations/Tetlan.jpg";

SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

export const About = () => {
  const history = useHistory();
  return (
    <>
      <HeroContainer src={Background}>
        <HeroTitleContainer>
          <HeroRedTitle>Bristroil en Guadalajara</HeroRedTitle>
        </HeroTitleContainer>
        <BlurAbsolute />
      </HeroContainer>
      <SectionStyledFull>
        <TitleWrapper>
          <div>
            <MiniTitle>Nosotros</MiniTitle>
            <Title color="primary">
              Ahorro y
              <br />
              Rendimiento
              <br />
              <TitleStrong>posible contigo</TitleStrong>
            </Title>
          </div>
        </TitleWrapper>
        <InfoContainer>
          <p>
            Somos un grupo gasolinero con más de 20 años de experiencia que
            decidió independizarse para así tener una atención especializada para
            los clientes logrando encontrar los mejores proveedores de expendio
            de combustibles para tener el mejor precio de la zona, orientada a
            la satisfacción de nuestros clientes, comprometida con sus
            colaboradores y la sociedad.
          </p>
        </InfoContainer>
        <ValuesWrapper>
          <ValuesTitle>Algunos de nuestros valores</ValuesTitle>
          <ValuesSquaresRow>
            <SquareValues>
              <h4>Honestidad</h4>
            </SquareValues>
            <SquareValues>
              <h4>
                Trabajo en
                <br />
                Equipo
              </h4>
            </SquareValues>
            <SquareValues>
              <h4>
                Atención
                <br />
                Personalizada
              </h4>
            </SquareValues>
          </ValuesSquaresRow>
        </ValuesWrapper>
        <TitleWrapper>
          <div>
            <MiniTitle>Estaciones</MiniTitle>
            <Title color="primary">
              8 Estaciones
              <br />
              <TitleStrong>contigo</TitleStrong>
            </Title>
          </div>
        </TitleWrapper>
        <StationsImagesWrapper>
          <StationImage color="primary" left src={BackgroundStations1} />
          <StationImage color="primary" center src={BackgroundStations2} />
          <StationImage color="primary" right src={BackgroundStations3} />
          <StationButtonContainer>
            <Button onClick={() => history.push("/estaciones")} color="primary">
              Ver más
            </Button>
          </StationButtonContainer>
        </StationsImagesWrapper>
        <StationsImagesWrapperMobile>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoHeight
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 1000*5,
              disableOnInteraction: false,
            }}
          >          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Belisario} alt="Belisario" />
              </SliderWrapperMobile>
            </SwiperSlide>     
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Caji} alt="Caji" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={RealAColima} alt="RealAColima" />
              </SliderWrapperMobile>
            </SwiperSlide>         
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Robles} alt="Robles" />
              </SliderWrapperMobile>
            </SwiperSlide>     
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={SanSebastian} alt="SanSebastian" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={SantaPaula} alt="SantaPaula" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Tepic} alt="Tepic" />
              </SliderWrapperMobile>
            </SwiperSlide>          
            <SwiperSlide>
              <SliderWrapperMobile>
                <ImageSliderMobile src={Tetlan} alt="Tetlan" />
              </SliderWrapperMobile>
            </SwiperSlide>      
          </Swiper>
          <StationButtonContainer>
            <Button onClick={() => history.push("/estaciones")} color="primary">
              Ver más
            </Button>
          </StationButtonContainer>
        </StationsImagesWrapperMobile>
      </SectionStyledFull>
    </>
  );
};
