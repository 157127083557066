import { Link } from "react-router-dom";
import styled from "styled-components";
import { deviceMF, deviceDF } from "../breakPoints";
import { white, red, blue } from "../colors";

export const FooterStyled = styled.footer`
  width: 100%;
  padding: 150px 2rem 1.5rem;
  color: ${white};
  background-color: ${red};
  display: flex;
  flex-direction: column;
  position: relative;
  @media ${deviceMF.tablet} {
    padding: 220px 4rem 1.5rem;
  }
`;
export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  flex-direction: column;
  @media ${deviceMF.tablet} {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  @media ${deviceDF.mobileL} {
    margin-bottom: 3rem;
  }
`;
export const FooterCopyright = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
`;
export const FooterCopyrightLeft = styled.h6`
  font-size: 12px;
  margin: 0;
  text-align: center;
  @media ${deviceMF.tablet} {
    text-align: start;
  }
`;
export const FooterCopyrightRight = styled.h6`
  font-size: 12px;
  margin: 0;
  text-align: right;
  @media ${deviceMF.tablet} {
    text-align: start;
  }
  @media ${deviceDF.mobileL} {
    display: none;
  }
`;
export const FooterItemTitle = styled.h4`
  font-size: 20px;
  margin: 0 0 0.9rem;
  text-align: center;
  @media ${deviceMF.tablet} {
    text-align: start;
  }
  @media ${deviceDF.mobileL} {
    margin: 0;
  }
`;
export const FooterLink = styled.a`
  color: ${white};
  font-family: "Conv_Poppins-Bold", Sans-Serif;
  text-decoration: none;
`;
export const IconStyled = styled.img`
  width: 28px;
  margin-right: 1rem;
  @media ${deviceDF.mobileL} {
  margin-right: 0;
  margin-left: 1rem;
  }
`;
export const LinkStyled = styled(Link)`
  color: ${white};
  font-family: "Conv_Poppins-Bold", Sans-Serif;
  text-decoration: underline;
  font-size: 18px;
  text-underline-offset: 5px;
`;
export const FooterText = styled.p`
  color: ${white};
  font-family: "Conv_Poppins-Bold", Sans-Serif;
  text-align: center;
  font-size: 18px;
  @media ${deviceMF.tablet} {
    text-align: start;
  }
`;
export const FooterBadge = styled.div`
  background-color: ${(props) => (props.color !== "primary" ? white : blue)};
  color: ${(props) => (props.color !== "primary" ? blue : white)};
  width: 90%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  @media ${deviceMF.tablet} {
    width: 90%;
    height: 280px;
  }
`;
export const FooterBadgeImage = styled.img`
  display: none;
  @media ${deviceMF.tablet} {
    display: inline;
    width: 300px;
  }
`;
export const FooterBadgeInfo = styled.div`
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  @media ${deviceMF.tablet} {
    width: auto;
    padding: 1.8rem;
  }
`;
export const FooterBadgeTitle = styled.h2`
  font-size: 28px;
  line-height: 2.5rem;
  margin-bottom: 1rem;

  @media ${deviceMF.tablet} {
    line-height: 3.2rem;
    text-align: start;
    margin-bottom: 1.4rem;
    font-size: 48px;
  }
`;
export const FooterBadgeTitleStrong = styled.strong`
  font-family: "Conv_Poppins-Bold";
`;
export const SpanStyled = styled.span`
  font-size: 18px;
  @media ${deviceMF.tablet} {
    font-size: 34px;
  }
`;
export const InfoWrapper = styled.div`
  margin-bottom: 1.6rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 0;
  }
`;
export const FollowUs = styled.div`
  margin-bottom: 1.6rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 0;
  }
  @media ${deviceDF.mobileL} {
    display: none;
  }
`;
export const FollowUsMobileScreen = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 0;
  }
  @media ${deviceMF.mobileL} {
    display: none;
  }
`;
export const AnchorStyled = styled.a`
  font-size: 18px;
  text-decoration: none;
  color: ${(props) => (props.color !== "white" ? white : blue)};
  @media ${deviceMF.tablet} {
    font-size: 34px;
  }
`;
