import { createGlobalStyle } from "styled-components";
import { blue, white } from "./colors";

import PoppinsBoldTtf from "../fonts/Poppins-Bold.ttf";
import PoppinsBoldWoff from "../fonts/Poppins-Bold.woff";
import PoppinsBoldEot from "../fonts/Poppins-Bold.eot";
import PoppinsBoldSvg from "../fonts/Poppins-Bold.svg";
import PoppinsRegularTtf from "../fonts/Poppins-Regular.ttf";
import PoppinsRegularWoff from "../fonts/Poppins-Regular.woff";
import PoppinsRegularEot from "../fonts/Poppins-Regular.eot";
import PoppinsRegularSvg from "../fonts/Poppins-Regular.svg";
import PoppinsSemiBoldItalicTtf from "../fonts/Poppins-SemiBoldItalic.ttf";
import PoppinsSemiBoldItalicWoff from "../fonts/Poppins-SemiBoldItalic.woff";
import PoppinsSemiBoldItalicEot from "../fonts/Poppins-SemiBoldItalic.eot";
import PoppinsSemiBoldItalicSvg from "../fonts/Poppins-SemiBoldItalic.svg";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Conv_Poppins-Bold';
    src: url(${PoppinsBoldEot});
    src: 
        url(${PoppinsBoldWoff}) format('woff'),
        url(${PoppinsBoldTtf}) format("truetype"),
        url(${PoppinsBoldSvg}) format('svg');
        font-weight: normal;
        font-style: normal;
    }
  @font-face {
    font-family: 'Conv_Poppins-Regular';
    src: url(${PoppinsRegularEot});
    src: 
        url(${PoppinsRegularWoff}) format('woff'),
        url(${PoppinsRegularTtf}) format("truetype"),
        url(${PoppinsRegularSvg}) format('svg');
        font-weight: normal;
        font-style: normal;
    }
  @font-face {
    font-family: 'Conv_Poppins-SemiBoldItalic';
    src: url(${PoppinsSemiBoldItalicEot});
    src: 
        url(${PoppinsSemiBoldItalicWoff}) format('woff'),
        url(${PoppinsSemiBoldItalicTtf}) format("truetype"),
        url(${PoppinsSemiBoldItalicSvg}) format('svg');
        font-weight: normal;
        font-style: normal;
    }

  html {
    box-sizing: border-box;
    font-family: 'Conv_Poppins-Regular',Sans-Serif;
    background-color: ${blue};
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    cursor: pointer;
    text-decoration:none;
  }
 
  ul, li, h1, h2, h3, p  {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  button {
    border: 0;
    outline: 0;
  }

  body {
    max-width: 1920px;
    margin: 0 auto;
    background-color: ${white};
    color: ${blue};
    overscroll-behavior: none;
    width: 100%;
  }

  body {
    margin: 0 auto;
    /* overscroll-behavior: none; */
    width: 100%;
  }

  #app {
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    overflow-x: hidden;
  }
`;
